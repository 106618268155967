* {
    font-family: 'Raleway', sans-serif;
    box-sizing: border-box; 
}

.home-page-container {
    display: flex;
    flex-direction: column;
    width: 100%; 
}

.content {
    margin-left: 20px;
    margin-right: 20px;
}

.user-profile-container {
    display: flex;
    font-family: 'Raleway', sans-serif;
    margin-top: 20px;
    width: 100%;
    /* no wrap */
    flex-wrap: nowrap;
  }

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    align-items: flex-start;
    margin: 0px;
}

.card-left, .card-right {
    margin: 0px !important;
    margin-bottom: 0px !important;
    flex: 1 1 21%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: flex-start;
    background: #333;
    color: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card-left {
    border-radius: 20px 0 0 20px;
    background: #000;
    margin: 10px 0px 10px 10px;
}

.card-right {
    border-radius: 0 20px 20px 0;
    background: linear-gradient(to right, #000, #808080);
    margin: 10px 10px 10px 0px;
}

.card-left h2, .card-right h2 {
    font-size: 1rem; 
    margin: 5px;
    color: #FFF; 
    display: block; /* Ensure the text is displayed as a block */
}

.card-left p, .card-right p, .card-left-percentage p, .card-right-percentage p {
    display: block; /* Ensure the numbers are displayed as a block */
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    margin: 5px;
    white-space: nowrap;
    text-align: left; /* Align text to the left */
}

.card-right p {
    font-size: 1.5rem;
}

.card-percentage {
    font-size: 1rem; 
    font-weight: normal; 
    margin-top: 0.5rem;
}

.card-content {
    display: flex;
    flex-direction: column; /* Change to column */
    align-items: left; /* Center align items */
    justify-content: left; /* Center justify items */
    padding: 10px;
}

.card-image img {
    width: 100%;
    height: auto;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.main-content {
    flex-grow: 1;
    /* margin-left: 15rem; */
}

.button-container-normal, .button-container-outline, .logout-button {
    padding: 10px 30px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    height: 50px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 7px;
}

.user-profile-container {
    width: 100%;
}

.user-profile-settings {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 50px;

}

.user-profile-settings h1 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.button-container-normal {
    color: #fff;
    background: linear-gradient(to right, #000, #808080);
    border: none;
    width: 100%;
    margin-top: 30px;
}

.button-container-outline {
    color: #000;
    background-color: #fff;
    border: 2px solid #000;
    width: 48%;
}

.logout-button {
    color: #fff;
    background-color: #000;
    border: none;
    margin-top: 90px;
}

.sidebar {
    width: 4rem;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: #e9e6e6;
    transition: width 0.3s;
    overflow: hidden;
    border-radius: 0 15px 15px 0;
}

.toggle-button img {
    width: auto;
    height: 30px;
    cursor: pointer;
}

.sidebar.collapsed {
    width: 7rem;
    background: #f4f4f4;
}

.sidebar a {
    color: black;
    font-weight: 600;
    padding: 1rem;
    text-decoration: none;
    border-radius: 10px;
    font-style: italic;
}

.sidebar a.active {
    background-color: #333;
    color: white; 
    border-radius: 0 10px 10px 0;
}

@media (max-width: 768px) {
    .sidebar {
        width: 10rem;
        background-color: transparent;
    }
    .card-left, .card-right {
        margin-top: 20px;
    }
}

.liquidation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
}

.liquidation-card {
    background: #E9E9E9;
    width: 49%;
    max-height: 200px;
    padding: 10px;
    border-radius: 10px;
    color: black;
    text-align: left;
}

.liquidation-card h2 {
    font-size: 8px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.liquidation-card p {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
    margin-left: 10px;
}

.user-profile-sidebar {
    padding: 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: left;
    flex-wrap: nowrap;
    white-space: nowrap; /* Prevent text wrapping */
}
  
.user-profile-sidebar-title {
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: left;
    width: 100%;
    margin-left: 0px;
}

.user-profile-sidebar-links {
    list-style: none;
    padding: 0;
    width: 100%;
    padding-right: 0px;
    text-align: left;
    font-style: italic;
}

.user-profile-sidebar-links li {
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
    text-align: left;
    margin-bottom: 10px;
}

.user-profile-sidebar-links li.active {
    background-color: rgba(0, 0, 0, 0.1);
    color: black;
    border-radius: 0 10px 10px 0;
}

.user-profile-sidebar-links li:not(.active):hover {
    background-color: #ddd;
    border-radius: 0 10px 10px 0;
}

@media (max-width: 1018px) {
    .card-left, .card-right {
        max-width: 49%;
        border-radius: 15px;
        margin: 0px;
        padding: 15px; /* Reduced padding */
    }

    .card-left p, .card-right p, .card-left-percentage p, .card-right-percentage p {
        font-size: 1.2rem; /* Reduced font size */
        margin: 3px; /* Reduced margin */
    }

    .liquidation-card p {
        font-size: 1.5rem;
    }

    .card-percentage {
        font-size: 0.9rem; /* Smaller font size for percentage */
        margin-top: 0.5rem;
        padding: 0; /* Remove padding if any */
    }

    .card-content {
        padding: 0px; /* Smaller padding */
    }
}

@media (max-width: 768px) {
    .sidebar {
        width: 10rem; /* Adjust width if needed */
    }

    .card-right {
        margin-top: 30px !important;
    }

    .card-left, .card-right {
        flex-basis: 100%; /* Take full width on very small screens */
        max-width: 100%; /* Use full width to avoid overflow */
    }

    .card-left p, .card-right p, .card-left-percentage p, .card-right-percentage p {
        font-size: 1.5rem; /* Further reduce font size on smaller screens */
    }
}

@media (max-width: 750px) {
    .user-profile-settings {
      padding: 0px;
      margin-left: 0px;
    }
  
    .user-profile-settings-item {
      padding: 12px 0px;
      margin: 0px;
    }
  
    .user-profile-settings-item-label {
      font-size: 0.9em;
    }
  
    .user-profile-sidebar {
      margin-top: 20px;
      width: 100%;
      overflow-x: scroll;
      flex-direction: row;
      justify-content: space-around;
      margin-bottom: 20px;
      min-height: auto;
      align-items: center;
    }
  
    .user-profile-sidebar-links {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      align-items: center;
    }
  
    .user-profile-sidebar-links li {
      padding: 10px 20px;
      cursor: pointer;
      font-weight: 600;
      text-align: center;
      white-space: nowrap; /* Prevent text from wrapping */
      transition: background-color 0.3s ease;
  
    }
  
    .user-profile-sidebar-links li.active {
      background-color: #ddd; /* Adjust active background color as needed */
      border-radius: 10px 10px 0 0;
    }
  
    .user-profile-sidebar-links li:not(.active):hover {
      background-color: #eee; /* Adjust hover background color as needed */
    }

    .content {
        margin-top: 10px !important;
        margin-bottom: 30px !important;
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
  }