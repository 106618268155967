/* Ajustes.css */

.ajustes-container {
  display: flex;
}

.sidebar {
  width: 150px;
  background: #e9e6e6;
  padding: 20px;
}

.sidebar .logo img {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.sidebar-link {
  display: block;
  margin: 10px 10px 10px -12px;
  color: black;
  text-decoration: none;
}

.sidebar-link.active {
  font-weight: 1200;
  background-color: #e0e0e058;
}

.main-content {
  flex-grow: 1;
  /* padding: 20px; */
  /* max-width: 60% */
}

.profile-section{
  margin-top: 10px;
  margin-bottom: 40px;
}

.profile-section, .bank-section, .personal-section {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-header img {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* Makes the image circular */
  border: 2px solid #ddd; /* Adds a border around the image */
  margin-right: 20px;
  object-fit: contain !important;
}

.profile-header div {
  flex-grow: 1;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #aaa;
  transition: 0.2s ease all;
  pointer-events: none;
  font-weight: 900;
}

.input-group input[type="text"],
.input-group select,
.input-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #F3F3F3;
  color: #000;
  font-weight: 700;
}

.input-group textarea {
  resize: vertical;
  height: 100px;
}

.input-group input::placeholder,
.input-group select::placeholder,
.input-group textarea::placeholder {
  color: #8D8D8D;
}

.input-group input:focus + .input-label,
.input-group select:focus + .input-label,
.input-group textarea:focus + .input-label,
.input-group input:not(:placeholder-shown) + .input-label,
.input-group select:not([value=""]) + .input-label,
.input-group textarea:not(:placeholder-shown) + .input-label,
.input-label.filled {
  top: -10px;
  left: 10px;
  font-size: 12px;
  color: #333;
}

.button-settings {
  background: #000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 50px;
  font-weight: 700;
}
