.signup {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .signup form {
    display: flex;
    flex-direction: column;
  }
  
  .signup form input {
    padding: 10px;
    margin: 5px 0;
    border-radius: 7px;
    border: none;
    background-color: #f3f3f3;
  }
  
  .signup form button {
    padding: 15px 0;
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }

  .go-back-button {
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 15px 0;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }

  .error {
    color: red;
  }
  
  .link {
    color: #337ab7;
    text-decoration: none;
    font-weight: 600;
  }
  
  .link:hover {
    text-decoration: underline;
  }

  .terms-conditions, .account-has {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 700;
  }

  .back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 7px; /* Make edges rounded with 7px */
    background-color: #f3f3f3; /* Background color */
    cursor: pointer;
    border: none;
  }
  
  .back-button img {
    width: 50%; /* Reduce the size of the arrow */
    height: auto;
  }