/* OnboardingForm.css */

/* Global styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f7f8fc;
    margin: 0;
    padding: 0;
}

/* Container */
.onboarding-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

/* Headings */
.onboarding-container h2 {
    font-size: 24px;
    color: #333333;
    margin-bottom: 20px;
    text-align: left;
}

/* Input fields */
.onboarding-container input[type="text"],
.onboarding-container input[type="email"],
.onboarding-container input[type="password"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: none;
    font-weight: 700;
    border-radius: 4px;
    font-size: 12px;
}

/* Buttons */
.onboarding-container button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.onboarding-container button:hover {
    opacity: 0.9;
}

.onboarding-container button:focus {
    outline: none;
}

/* Button colors */
.onboarding-container button.primary {
    background-color: #000;
    color: #ffffff;
}

.onboarding-container button.secondary {
    background-color: #6c757d;
    color: #ffffff;
}

/* Alignment */
.onboarding-container .button-group {
    display: flex;
    justify-content: space-between;
}

.input-group {
    position: relative;
    margin-bottom: 15px;
}

input.error {
    border: 1px solid #ff0000;
}

.error-message {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
    display: block;
}
