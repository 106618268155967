* {
  font-family: 'Raleway', sans-serif;
}

.sales-page-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  box-sizing: border-box;
  width: 100%;
  margin-left: 50px;
}

.sidebar {
  width: 150px;
  background: #e9e6e6;
  padding: 20px;
}

.sidebar.collapsed {
  width: 8rem;
}

.toggle-button img {
  width: 100%;
}

.sidebar-link {
  display: block;
  margin: 10px 10px 10px -12px;
  color: black;
  text-decoration: none;
}

.sidebar-link.active {
  font-weight: 1200;
  background-color: #e0e0e058;
}

.main-content {
  flex-grow: 1;
}

.sales-list {
  width: 100%;
}

.sale-item {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
}

.sale-header {
  display: flex;
  justify-content: space-between;
}

.sale-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.sale-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.sale-info {
  display: flex;
  flex-direction: column;
}

.status {
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 10px;
  color: black;
  margin-bottom: 5px;
}

.status.pending {
  border: 1px solid rgb(255, 192, 56);
  background-color: rgb(255, 220, 145);
}

.status.approved {
  background-color: rgb(145, 255, 145);
  border: 1px solid rgb(56, 252, 56);
}

.dropdown-content {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  transition: max-height 0.3s ease-out;
}

.product-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 20px;
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-details h4 {
  margin: 0 0 5px 0;
  font-size: 1.2rem;
  font-weight: 700;
}

.product-details p {
  margin: 0;
  font-size: 1rem;
}

.view-items {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 5px;
}

.view-items:hover {
  color: #0056b3;
}

.sale-item input[type="checkbox"] {
  accent-color: black;
}

.print-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 7px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.print-button:hover {
  background-color: #333;
}

.select-all-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.select-all-container input[type="checkbox"] {
  accent-color: black;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.select-all-label {
  font-size: 14px;
  color: #666;
}

.print-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.print-options-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  margin-top: 5px;
  min-width: 150px;
}

.print-option {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.print-option:hover {
  background-color: #f5f5f5;
}

.print-option:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.filter-bubble {
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.filter-bubble:hover {
  background-color: #f5f5f5;
}

.filter-bubble.active {
  background-color: black;
  color: white;
  border-color: black;
}

.print-button-container {
  margin-left: auto;
}